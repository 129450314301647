html {
  box-sizing: border-box;
}

html, body, #root {
  padding: 0px !important;;
  margin: 0px !important;;
  height: 100%;
  width: 100%;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #333A3F;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.flex-column, .flex-row {
  display: flex;

  &.centered {
    align-items: center;
    justify-content: center;
  }
}

.flex-column { flex-direction: column; }
.flex-column { flex-direction: row; }

:root {
  --color-primary: #2A8B86;
  --color-secondary: #9ADA7E;
  --bp-lg: 1280px;
  --bp-md: 960px;
  --bp-sm: 600px;
}
