@import "~flat-ui-colors-sass/flat-colors";
@import url('https://fonts.googleapis.com/css2?family=Solway:wght@500&display=swap');

.nava-header {
	width: 100%;
		height: calc(100% + 60px);
	padding-bottom: 80px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: relative;

	background-color: #333a3f;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%232a8b86'/%3E%3Cstop offset='1' stop-color='%23333a3f'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%239ada7e' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%239ada7e' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.4'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
	background-attachment: scroll;
	background-size: cover;
	background-position: 50% 0;
	box-shadow: inset 0 -65px 25px -25px #32616B;

	.logo-wrapper {
		display: inline-flex;
		text-align: center;

		&:hover svg {
			transform: scaleX(1.2);
		}

		.logo-bubble {
			position: relative;
			display: inline-block;
			margin: 0 auto;

			> div {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;
				transform: rotate(180deg);
			}
		}
	}

	.title {
		display: inline-flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		opacity: 0.7;

		> div {
			//white-space: nowrap;

			span {
				//transition: all 0.2s ease;
				cursor: default;
				font-size: 2.2em;
				font-family: 'Solway', cursive;
				color: #ffffff;
				text-shadow: 0 0 3px black;
				@media (max-width: 690px) { font-size: 1.8em; }
				@media (max-width: 465px) { font-size: 1.4em; }
				@media (max-width: 285px) { font-size: 1.2em; }

				&:hover {
					transform: scale(1.2);
				}
			}
		}

		small {
			transition: all 0.2s ease;
			cursor: default;
			font-size: 2.2em;
			font-family: 'Solway', cursive;
			color: #ffffff;
			text-shadow: 0 0 3px black;
			@media (max-width: 690px) { font-size: 1.8em; }
			@media (max-width: 465px) { font-size: 1.4em; }
			@media (max-width: 285px) { font-size: 1.2em; }

			&:hover {
				transform: scale(1.2);
			}
		}
	}
}
