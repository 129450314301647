@import "~flat-ui-colors-sass/flat-colors";
@import url('https://fonts.googleapis.com/css2?family=Solway:wght@500&display=swap');

.nava-test {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 30px;
	width: 100%;
	height: 100%;
	background-color: $flat-amethyst;

	&.red { background-color: $flat-red-2 }
}

.nava-section {
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	position: relative;

	.test-svg {
		width: 100%;
	}
}

.nava-cube {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 60px;
	height: 60px;
	border-radius: 5px;
	color: $flat-blue-4;
	background-color: $flat-concrete;
}

.nava-loader {
	height: 100%;
	width: 100%;
}

.nava-title {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	position: relative;
}

.card-titled {
	padding: 16px 120px !important;

	h2 {
		font-size: 2.4rem;
		font-weight: 400;
	}
}
